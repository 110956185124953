import React, { Fragment } from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';

import Layout from '../../components/layout';
import Header from '../../components/header';
import SEO from '../../components/seo';

import { marcoList } from '../../utils/tu-instituto/constants';
import { useStyles } from '../../utils/tu-instituto/styles';
import { useGlobalStyles } from '../../utils/styles';

const MarcoJuridico = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <Layout>
      <SEO title="Marco juridico" />

      <div className={globalClasses.container}>
        <Header title="Marco jurídico e institucional" />

        <div className={globalClasses.listContent}>
          <List disablePadding dense className={globalClasses.list}>
            {marcoList.map((list, index) => (
              <Fragment key={index}>
                <ListItem className={globalClasses.listTitle}>
                  <ListItemText primary={list.title} />
                </ListItem>
                {list.elements.map((item, i) => (
                  <ListItem key={i} button className={globalClasses.listElement} onClick={() => window.open(item.file)}>
                    <ListItemText primary={item.name} />
                  </ListItem>
                ))}
              </Fragment>
            ))}
          </List>
        </div>
      </div>
    </Layout>
  );
};

export default MarcoJuridico;